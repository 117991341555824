<!--
 设置
-->

<template>
  <div>
    <div class="page-bg"></div>
    <div class="setting-box" :style="!isApp ? 'padding-top:70px' : ''">
      <!-- 没有邀请码且注册时间小于48小时显示 -->
      <div v-if="!inviteCode && diffTime() < 48" class="myInvite">
        <div class="inviteName">我的邀请人</div>
        <div class="codeInput">
          <van-field
            class="input"
            :border="false"
            v-model="inputInviteCode"
            placeholder="输入邀请人的邀请码"
          />
          <div class="saveBtn" @click="saveCode">保存</div>
        </div>
      </div>
      <div v-if="inviteCode" class="inviteName">我的邀请人：{{ inviteCode }}</div>
      <ul class="user-list">
        <li @click="goTo(isVerify ? '/authsuccess' : '/authentication', '实名认证')">
          <!-- <img class="icon_icon" :src="icon_sm" /> -->
          <span>实名认证</span>
          <span class="authentication-text">{{ isVerify ? '已实名' : '未实名' }}</span
          ><van-icon :name="icon_arrow" class="icon_arrow" />
        </li>

        <li v-if="!isVerify" @click="modifyPhone">
          <!-- <img class="icon_icon" :src="icon_sm" /> -->
          <span>修改手机号</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
      </ul>

      <!-- <van-button v-if="!isVerify" class="setting-box-btn" type="primary" @click="modifyPhone"
        >修改手机号</van-button
      > -->
      <van-button class="setting-box-btn setting-box-exit" type="primary" @click="handleLogout"
        >退出登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import { setinvitecode } from '@/service/invite';
import { localStorageUtil, nftUtils } from '@/utils';

export default {
  setup() {
    const router = useRouter();
    const toastFn = inject('$toast');
    const store = useStore();
    const state = reactive({
      isApp: nftUtils.isInApp(),
      isVerify: false,
      inputInviteCode: null,
      inviteCode: null,
      server_time: null,
      created_time: null
    });

    onMounted(async () => {
      if (nftUtils.isLogin()) {
        state.isVerify = !!store.state.loginInfo.id_verify;
        await store.dispatch('refreshLoginInfo');
        state.isVerify = !!store.state.loginInfo.id_verify;
        state.inviteCode = store.state.loginInfo.invite_code;
        state.created_time = store.state.loginInfo.created_time;
        state.server_time = Number(localStorageUtil.get('server_time'));
      }
    });

    const modifyPhone = async () => {
      nftUtils.setcnzzRecordByBtn('修改手机号');
      router.push({ path: './modifyphone' });
    };
    const handleLogout = async () => {
      nftUtils.setcnzzRecordByBtn('退出登录');
      localStorageUtil.del('accessToken');
      localStorageUtil.del('modelFlag');
      router.push({ path: '/', replace: true });
    };

    // 对比时间是否超过48小时
    const diffTime = () => {
      return moment(state.server_time).diff(
        moment(Number(moment(state.created_time).format('x'))),
        'hours'
      );
    };

    const saveCode = () => {
      if (state.inputInviteCode) {
        nftUtils.setcnzzRecordByBtn('保存邀请人的邀请码');
        setinvitecode({
          invite_code: state.inputInviteCode
        }).then(res => {
          if (res.status === 0) {
            state.inviteCode = state.inputInviteCode;
          } else {
            toastFn({ message: res.message });
          }
        });
      }
    };

    const goTo = (r, title) => {
      nftUtils.setcnzzRecordByBtn(title);
      router.push({ path: r });
    };

    return {
      ...toRefs(state),
      goTo,
      modifyPhone,
      handleLogout,
      diffTime,
      saveCode,
      icon_sm: require('../imgs/icon/icon_mine_sm.png'),
      icon_arrow: require('../imgs/icon/icon_mine_jt.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.setting-box {
  position: relative;
  padding-top: 40px;
  &-btn {
    width: 95%;
    height: 56px;
    align-content: center;
    left: 50%;
    margin-top: 12px;
    transform: translateX(-50%);
    background-color: $primaryc;
  }
  &-exit {
    color: $redc;
  }

  .myInvite {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .codeInput {
    display: flex;
    justify-content: space-between;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
    .input {
      width: 224px;
      height: 44px;
      background: #2f293f;
      border-radius: 4px 4px 4px 4px;
      color: white;
    }
  }

  .saveBtn {
    width: 99px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #1c172a;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
  }

  .inviteName {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    padding: 24px 0;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }

  .user-list {
    padding: 0 16px;
    margin-top: 0px;
    li {
      height: 56px;
      line-height: 56px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: 14px;
      background-color: $primaryc;
      border-radius: 5px;
      padding: 0 10px 0 16px;
      align-items: center;
      span {
        width: 90%;
      }
      .icon_arrow {
        width: 16px;
        height: 16px;
      }
      .icon_icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .authentication-text {
        text-align: right;
        margin-right: 10px;
        font-size: 14px;
        opacity: 0.8;
      }
    }
  }
}
</style>
